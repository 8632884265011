import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { SwitchExample } from './utils';
import { UiSwitch } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "uiswitch"
    }}>{`UiSwitch`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/form/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render switch inputs`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/form`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiswitch-1"
    }}>{`UiSwitch`}</h2>
    <Playground __position={1} __code={'<UiSwitch name=\"checkbox\" label=\"Select option\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSwitch name="checkbox" label="Select option" mdxType="UiSwitch" />
    </Playground>
    <h2 {...{
      "id": "uiswitch-with-positive-category"
    }}>{`UiSwitch with POSITIVE category`}</h2>
    <Playground __position={2} __code={'<UiSwitch name=\"positive-checkbox\" category=\"positive\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSwitch name="positive-checkbox" category="positive" mdxType="UiSwitch" />
    </Playground>
    <h2 {...{
      "id": "uiswitch-with-error-category"
    }}>{`UiSwitch with ERROR category`}</h2>
    <Playground __position={3} __code={'<UiSwitch name=\"error-checkbox\" category=\"error\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSwitch name="error-checkbox" category="error" mdxType="UiSwitch" />
    </Playground>
    <h2 {...{
      "id": "uiswitch-with-negative-category"
    }}>{`UiSwitch with NEGATIVE category`}</h2>
    <Playground __position={4} __code={'<UiSwitch name=\"negative-checkbox\" category=\"negative\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSwitch name="negative-checkbox" category="negative" mdxType="UiSwitch" />
    </Playground>
    <h2 {...{
      "id": "uiswitch-with-state-management"
    }}>{`UiSwitch with state management`}</h2>
    <Playground __position={5} __code={'<SwitchExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <SwitchExample mdxType="SwitchExample" />
    </Playground>
    <h2 {...{
      "id": "uiswitch-disabled"
    }}>{`UiSwitch disabled`}</h2>
    <Playground __position={6} __code={'<UiSwitch name=\"disabled-checkbox\" label=\"Select option\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSwitch name="disabled-checkbox" label="Select option" disabled mdxType="UiSwitch" />
    </Playground>
    <h2 {...{
      "id": "uiswitch-with-label-on-the-start"
    }}>{`UiSwitch with label on the start`}</h2>
    <Playground __position={7} __code={'<UiSwitch\n  name=\"start-label-checkbox\"\n  label=\"Select option\"\n  disabled\n  labelPosition=\"START\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      SwitchExample,
      UiSwitch,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSwitch name="start-label-checkbox" label="Select option" disabled labelPosition="START" mdxType="UiSwitch" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiSwitch} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      